<template>
  <div id="contents" class="process_progress_status">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="FETCH_PRODUCTION_LIST">
            새로고침 {{ timer }}
          </button>
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <process-status-form
        :key="key"
        @changetimer="changeTimer($event)"
      ></process-status-form>
    </div>
  </div>
</template>

<script>
import ProcessStatusForm from '@/views/forms/Process/ProcessStatus/ProcessStatusForm.vue';
// import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  data() {
    return {
      key: 0,
      timer: 0,
    };
  },
  mixins: [ModalMixin, SpinnerMixin, ClosePageMixin, FavoriteMixin],
  components: {
    ProcessStatusForm,
  },
  methods: {
    changeTimer(e) {
      console.log(e);

      this.timer = e;
    },
    async FETCH_PRODUCTION_LIST() {
      await this.$store
        .dispatch('FETCH_PRODUCTION_LIST')
        .then(() => {
          this.key += 1;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '공정 진행현황을 불러오는 중 오류 발생',
          );
        });
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      await this.$store.commit('InitProcessPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
